import React, {useEffect, useRef, useState} from "react";

import User from "../models/User";
import Swal from 'sweetalert2'
import {useHistory} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"

import qs from 'qs'

import moment from "moment"

import {Link, Element, Events, animateScroll as scroll, scrollSpy, scroller} from 'react-scroll'
import Forms from "../models/Forms";
import Util from "../utils/Util";

import background from "../assets/telin/Background.png"
import emptyLogin from "../assets/telin/emptylogin.png"
import boyPng from "../assets/telin/Boy.png"
import cardBg from "../assets/telin/cardbg.png"
import logoTelin2 from "../assets/telin/logotelin2.png"
import buttonBackground from "../assets/telin/Button.png"

import Card from "@material-ui/core/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import styles from "../values/styles";
import {isMobile, isOpera, isSafari} from "react-device-detect";

let requestCountDownTime = 0

export default function LandingPage(props) {

    const [activeHeader, setActiveHeader] = useState('home')
    const containerRef = useRef(null);

    const history = useHistory();
    const userModel = new User();
    const [loginCreds, setLoginCreds] = useState({});
    const [registerCreds, setRegisterCreds] = useState({});
    const [isLoginShown, setLoginShown] = useState(false)
    const [isRegisterShown, setRegisterShown] = useState(props.isRegisterShown)
    const [isForgotPasswordShown, setForgotPasswordShown] = useState(false)
    const [errors, setErrors] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [profile, setProfile] = useState({});
    const [isPlayingVideo, setIsPlayingVideo] = useState(false)

    const [competitionModal, setCompetitionModal] = useState(false)
    const [doorPrizeModal, setDoorPrizeModal] = useState(false)

    const [daysLeft, setDaysLeft] = useState(null)
    const [hourLeft, setHourLeft] = useState(null)
    const [minuteLeft, setMinuteLeft] = useState(null)
    const [secondLeft, setSecondLeft] = useState(null)

    const [remainingTime, setRemainingTime] = useState(0)
    const [clientHeight, setClientHeight] = useState(window.innerHeight)

    const cardRef = useRef(null);

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const isMobile = windowWidth < 768

    const getProfile = async () => {
        try {
            const profileTemp = await userModel.getMyProfile()

            // console.log("token", profileTemp.token)
            if(profileTemp.token){
                localStorage.setItem('token', profileTemp.token)
            }
            localStorage.setItem('gender', profileTemp.gender)
            localStorage.setItem('fullname', profileTemp.fullname)

            history.push('/expo')

            setProfile(profileTemp)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(function () {
        if (localStorage.getItem('token') && localStorage.getItem('token') !== undefined) {
            getProfile()
        }
    },[])

    useEffect(function () {

        if (isSafari || isMobile || isOpera) {
            Swal.fire({
                title: 'This browser may not support the virtual expo',
                text: 'For the best experience, please consider using the desktop version of Google Chrome or Microsoft Edge.',
                icon: 'info',
                confirmButtonText: 'OK'
            }).then((result) => {
                setLoginShown(true)
            })
        }

    }, []);

    const attemptLogin = async () => {
        const errorsTemp = {}

        /* if (!email) {
            return Swal.fire({
                title: 'Please fill in all data',
                icon: 'error',
            })
        }

        if (!password) {
            return Swal.fire({
                title: 'Please fill in all data',
                icon: 'error',
            })
        } */

        try {
            /* const response = await userModel.login({
                email,
                password
            }) */

            /* console.log("logindata", response)

            setProfile(response) */

            localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0MTcsImVtYWlsIjoiZ3Vlc3RAZ3Vlc3QuY29tIiwiaWF0IjoxNjk4OTgxOTY4LCJleHAiOjE2OTk1ODY3Njh9.NMyyvK-_T9TtsH-tZPX9PE9mt3iq5l3qe5ft0vrsyxU')
            localStorage.setItem('gender', 'M')
            localStorage.setItem('fullname', 'guest')
            

            history.push('/expo')

            /* console.log(response) */

            setLoading(false)
        } catch (e) {
            if (e.code === 'EMAIL_NOT_FOUND' || e.code === 'USER_PASSWORD_WRONG') {
                return Swal.fire({
                    title: 'Invalid Credential',
                    icon: 'error',
                })
            } else if (e.error_message === "ACCOUNT_UNVERIFIED") {
                Swal.fire({
                    title: 'Your account is not yet activated. Please check your email to activate your account.',
                    icon: 'warning',
                    confirmButtonText: 'Resend Email',
                    cancelButtonText: 'Close',
                    showCancelButton: true
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            await userModel.resendEmailValidation({
                                email: loginCreds.email
                            })

                            Swal.fire({
                                title: 'Verification email has been sent successfully.',
                                icon: 'success',
                            })
                        } catch (e) {
                            Swal.fire({
                                title: 'Internal Server Error',
                                text: "Please contact Support",
                                icon: 'error',
                            })
                        }
                    }

                    setLoginShown(true)
                })
            } else {
                errorsTemp.form = 'An error occurred.'
            }

            setLoading(false)
        }

    }

    return <div
        style={{
            background: `url(${background})`,
            width: "100vw",
            height: "100vh",
            backgroundSize: "cover",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}
        ref={containerRef}>

        <Card style={{
            height: "60vh",
            width: "60vw",
            borderRadius: 15
        }}>
            <img
                style={{
                    position: "absolute",
                    height: "40vh",
                    width: "24vh",
                    left: "calc(100% - 20vw - 3.368vh)",
                    bottom: "calc(0px + 20vh)",
                }}
                src={boyPng}/>

            <img
                style={{
                    position: "absolute",
                    height: "25vh",
                    width: "25vh",
                    left: "calc(100% - 20vw - 12.5vh)",
                    top: "calc(0px + 7.5vh)",
                }}
                src={logoTelin2}/>

            <Container
                style={{height: "100%"}}
                fluid>
                <Row style={{height: "100%"}}>
                    <Col
                        style={{
                            display: "flex",
                            fontFamily: "Montserrat",
                            color: "#4d0089",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                        }}
                        md={6}>

                        <div style={{
                            fontWeight: "bold",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "1.5em",
                        }}>
                            HELLO & WELCOME
                        </div>

                        <div style={{
                            fontWeight: "200",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "1.1em",
                            marginTop: "1em",
                            fontStyle: "italic",
                            color: "grey"
                        }}>
                            Let's get ready to enter <br/>
                            the Telin Digital Universe
                        </div>


{/*                         <Form.Group
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                width: "80%",
                                marginTop: 30
                            }}
                            className="mb-3"
                        >
                            <Form.Control
                                placeholder={"INSERT YOUR EMAIL"}
                                value={email ? email : ""}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                style={{
                                    ...styles.normalText,
                                    borderRadius: 50
                                }}/>

                        </Form.Group>

                        <Form.Group
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                width: "80%",
                            }}
                            className="mb-3"
                        >
                            <Form.Control
                                type={"password"}
                                placeholder={"PASSWORD"}
                                value={password ? password : ""}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                style={{
                                    ...styles.normalText,
                                    borderRadius: 50
                                }}/>

                        </Form.Group> */}
                        
                        <br />
                        <div
                            onClick={() => {
                                // alert("boi")
                                attemptLogin()
                            }}
                            style={{
                                background: `url(${buttonBackground})`,
                                width: "80%",
                                backgroundSize: "100% 100%",
                                fontStyle: "italic",
                                color: "white",
                                padding: 8,
                                textAlign: "center",
                                fontWeight: "400",
                                cursor: "pointer"
                            }}>
                            ENTER
                        </div>

                        <div style={{
                            fontWeight: "400",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "0.9em",
                            marginTop: "3em",
                            fontStyle: "italic",
                            color: "grey",
                        }}>
                            Let's #StayConnected
                        </div>

                    </Col>
                    <Col
                        style={{
                            background: `url(${cardBg})`,
                            height: "100%",
                            backgroundSize: "cover",
                            display: "flex",
                            fontFamily: "Montserrat",
                            color: "white",
                            flexDirection: "column",
                            justifyContent: "center",
                            paddingLeft: "2em"
                        }}
                        md={6}>
                        <br/>
                        <div style={{
                            marginTop: "2em",
                            fontSize: "1.5em",
                            fontWeight: "bold",
                        }}>
                            Welcome to
                        </div>
                        <div style={{
                            fontSize: "1.5em",
                            marginTop: "0.5em",
                            fontWeight: "bold",
                        }}>
                            Telin Digital Universe
                        </div>

                        {/* <div style={{
                            marginTop: "1em",
                            fontStyle: "italic",
                            fontWeight: "200",
                        }}>
                            Log in to access your account
                        </div> */}
                    </Col>
                </Row>
            </Container>
        </Card>

    </div>
}
