import ApiRequest from "../utils/ApiRequest";

export default class Admin {
    login = async (body) => {
        return await ApiRequest.set(`/v1/user/login`, "PUT", body);
    }

    register = async (body) => {
        return await ApiRequest.set(`/v1/user/register`, "POST", body);
    }

    resendEmailValidation = async (body) => {
        console.log(body)
        return await ApiRequest.set(`/v1/user/resend-email-validation`, "POST", body);
    }

    getMyProfile = async (body) => {
        return await ApiRequest.set(`/v1/user`, "GET");
    }

    forgotPassword = async (body) => {
        return await ApiRequest.set(`/v1/user/forgot/password`, "POST", body);
    }

    resetPassword = async (body) => {
        return await ApiRequest.set(`/v1/user/reset/password`, "POST", body);
    }
}
