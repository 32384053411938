import React, {useEffect, useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from '@material-ui/core/Card'

import HighScore from '../models/HighScore'
import background from '../assets/telin/Background.png'
import Button from "react-bootstrap/Button";

function HighscorePage(props) {

    let [highScores, setHighScores] = useState([])
    let [page, setPage] = useState(1)

    let highScoreModel = new HighScore()
    let gameId = props.match.params.gameId

    let gameTitle = gameId === "UFOEVD" ? "UFO EVADER" : "ZIGZAG"

    const getHighScores = async () => {
        try {
            const highScores = await highScoreModel.getAllHighScores(gameId)

            console.log(highScores)
            setHighScores(highScores.slice(0,50))

        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getHighScores()
    }, [])

    return (
        <div
            style={{
                background: `url(${background})`,
                width: 'object-fit',
                backgroundSize: 'cover',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: "100vh"
            }}
            className="text-center"
        >
            <Row className="pt-5 px-5 pb-3">
                <div
                    className="text-uppercase tw-bold text-center"
                    style={{fontSize: '45px', color: 'white', fontFamily : "Montserrat", fontWeight : "bold"}}
                >
                    {gameTitle} High Scores
                </div>
            </Row>
            <Row style={{
                display :"flex",
                alignItems :"center",
                justifyContent : "center"
            }}>
                <Col md={6}>
                    <Card
                        style={{
                            width : "100%",
                            opacity: '85%',
                            borderRadius: 15,
                        }}
                        className="d-inline-block"
                    >
                        <table style={{
                            width : "100%"
                        }}>

                            <tr className="mb-2" style={{fontFamily: 'Montserrat', fontWeight: 'bold', marginTop: 20}}>
                                <td className="px-3">Rank</td>
                                <td className="px-3">Name</td>
                                <td className="px-3">Score</td>
                            </tr>

                            {highScores &&
                            highScores.length > 0 &&
                            highScores.map((score, index) => {

                                let style = index % 2 === 0 ? '#A9A9A9' : '#D3D3D3'

                                console.log("PAGE RANGE", (page - 1) * 10, (page - 1) * 10 + 9)

                                if (!(index >= (page - 1) * 10 && index < (page - 1) * 10 + 10)) {
                                    return
                                }

                                return (
                                    <tr style={{backgroundColor: style}} className="text-center">
                                        <td
                                            style={{
                                                fontFamily: 'Montserrat',
                                                backgroundColor: style,
                                            }}
                                            className="mx-5 px-3 mb-1"
                                        >
                                            {index + 1}
                                        </td>
                                        <td
                                            style={{fontFamily: 'Montserrat', textTransform: "capitalize"}}
                                            className="mx-5 px-3 mb-1"
                                        >
                                            {score.fullname}
                                        </td>
                                        <td
                                            style={{fontFamily: 'Montserrat',}}
                                            className="mx-5 px-3 mb-1 text-center"
                                        >
                                            {score.score}
                                        </td>
                                    </tr>
                                )
                            })}

                        </table>

                        <div style={{
                            marginTop: 10,
                            marginBottom: 10,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end"
                        }}>
                            <Button
                                onClick={() => {
                                    if (page === 1) {
                                        return
                                    }
                                    setPage(page - 1)
                                }}
                                style={{color: "white", marginRight: 10}}>Previous</Button>
                            <Button
                                onClick={() => {
                                    if (page > (highScores.length - 1) / 10) {
                                        return
                                    }
                                    setPage(page + 1)
                                }}
                                style={{color: "white", marginRight: 10}}>Next</Button>
                        </div>

                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default HighscorePage
