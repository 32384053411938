import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import {useHistory, Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import {
    BiFullscreen, BsPeopleFill,
    FaChalkboardTeacher, FaKeyboard, FaQuestion, FaThumbsUp, FaTrophy,
    FiInstagram,
    FiYoutube, GiArrowCursor, GrTrophy,
    HiOutlineDesktopComputer,
    IoDocumentTextOutline, MdCameraswitch, RiSurveyLine
} from "react-icons/all";

import Unity, {UnityContext} from "react-unity-webgl";

import Palette from "../utils/Palette";

import {isSafari, isOpera, isMobile} from "react-device-detect";
import Swal from "sweetalert2";

import logoWhite from "../assets/telin/logotelin2.png";
import keyboardImage from "../assets/telin/keyboards.png";
import background from "../assets/telin/Background.png";
import buttonBG from "../assets/telin/Button.png";

import User from "../models/User";
import moment from "moment";
import Schedule from "../models/Schedule";

const unityContext = new UnityContext({
    loaderUrl: "build/Build.loader.js",
    dataUrl: "build/Build.data.unityweb",
    // dataUrl: "https://telin.s3.ap-southeast-1.amazonaws.com/build18/Build.data.unityweb",
    frameworkUrl: "build/Build.framework.js.unityweb",
    codeUrl: "build/Build.wasm.unityweb",
});


unityContext.on("error", function (message) {
    alert("An error occured. Please try refreshing the page or use another browser.")
});

const zoomUrl = "https://us02web.zoom.us/j/83343793503?pwd=bW9Hd2ZoaFlXWnFwTWpRcWxBRHU3UT09"

const TUTORIAL_NODES = [
    {
        title: "Welcome",
        content: "Welcome to Telin Virtual 2022!",
        icon: <img
            style={{
                marginTop: 15,
                height: "8em",
                objectFit: "contain"
            }}
            src={logoWhite}/>
    },
    {
        title: "Controls",
        content: "Use the WASD or your Arrow keys to move your avatar around the virtual exhibition area.",
        icon: <img
            style={{
                marginTop: 15,
                height: "8em",
                objectFit: "contain"
            }}
            src={keyboardImage}/>
    },
    {
        title: "Controls",
        content: "Use the QE keys to rotate your camera in the smart city.",
        icon: <MdCameraswitch
            style={{
                marginTop: 15
            }}
            size={"5em"}/>
    },
    {
        title: "Interact",
        content: "By pressing the screen, you can interact with different object in the exhibition area.",
        icon: <GiArrowCursor
            style={{
                marginTop: 30
            }}
            size={"5em"}/>
    },
    {
        title: "Meet",
        content: "You can meet your friends participating in the virtual room!",
        icon: <BsPeopleFill
            style={{
                marginTop: 30
            }}
            size={"5em"}/>
    },
    // {
    //     title: "Games",
    //     content: "Find treasures from 8 December - 9 December 2021 for a chance to get a doorprize!",
    //     icon: <FaTrophy
    //         style={{
    //             marginTop: 30
    //         }}
    //         size={"5em"}/>
    // },
]

export default function LandingPage(props) {
    const history = useHistory();
    const [isLoginShown, setLoginShown] = useState(false)

    const [progression, setProgression] = useState(
        0)
    const [tutorialModal, setTutorialModal] = useState(false)

    const [tutorialPage, setTutorialPage] = useState(0)

    const [isPopperShown, setIsPopperShown] = useState(true);

    const [profile, setProfile] = useState({});

    const userModel = new User();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [adUrl, setAdUrl] = useState("")
    const [adImage, setAdImage] = useState("")

    // const [nextSessionTitle, setNextSessionTitle] = useState("AM AWARD : BEST TARGET ACHIEVEMENT CES REGION III")
    const [nextSessionTitle, setNextSessionTitle] = useState(null)

    let scheduleModel = new Schedule()

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        getAd()

        let scheduleTimer = setInterval(() => {
            getAd()
        }, 5 * 60000)

        return () => clearInterval(scheduleTimer)

    }, [])

    const getAd = async () => {
        try {

            let ad = await scheduleModel.getAd()
            setNextSessionTitle(ad.title)

        } catch (e) {
            console.log(e)
            return {image_url: "", video_url: ""}
        }
    }


    useEffect(function () {
        if (!localStorage.getItem('token')) {
            history.push('/login')
        } else {
            unityContext.on("progress", function (progression) {
                setProgression(progression);
            });
        }

        if(localStorage.getItem('token') === "undefined" || localStorage.getItem('token') === undefined){
            history.push('/login')
        }

        if (!localStorage.getItem('first_visit')) {
            localStorage.setItem('first_visit', "true")
            setTutorialModal(true)
        }

        if (isSafari || isMobile || isOpera) {
            Swal.fire({
                title: 'This browser may not support the virtual expo',
                text: 'For the best experience, please consider using the desktop version of Google Chrome or Microsoft Edge.',
                icon: 'info',
                confirmButtonText: 'OK'
            }).then((result) => {
                setLoginShown(true)
            })
        }

    }, []);

    const getProfile = async () => {
        try {
            const profileTemp = await userModel.getMyProfile()

            console.log('profileTemp', profileTemp)

            setProfile(profileTemp)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getProfile()
        }
    }, [])

    return <>
        <Modal
            show={tutorialModal}
            size={"xs"}
        >

            <Modal.Body style={{
                backgroundColor: Palette.TELIN_PINK,
                height: 350,
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
            >
                <div style={{
                    fontSize: "1.5em"
                }}>
                    {TUTORIAL_NODES[tutorialPage].title}
                </div>


                {TUTORIAL_NODES[tutorialPage].icon}


                <div style={{
                    width: "80%",
                    textAlign: "center",
                    marginTop: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {TUTORIAL_NODES[tutorialPage].content}
                </div>

                <div style={{
                    marginTop: 20,
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                    {
                        TUTORIAL_NODES.map((obj, index) => {
                            return <div style={{
                                width: 10,
                                height: 10,
                                borderRadius: 20,
                                marginLeft: 5,
                                marginRight: 5,
                                backgroundColor: tutorialPage === index ? "white" : Palette.TELIN_DARK_PINK
                            }}/>
                        })
                    }

                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 30,
                    width: "100%"
                }}>
                    {
                        tutorialPage !== 0 &&
                        <div
                            onClick={() => {
                                setTutorialPage(tutorialPage - 1)
                            }}
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            Previous
                        </div>
                    }


                    <div style={{
                        flex: 1,
                        width: "100%",
                        height: 10,
                    }}>

                    </div>

                    {
                        tutorialPage === TUTORIAL_NODES.length - 1 ?
                            <div
                                onClick={() => {
                                    setTutorialModal(false)
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                            >
                                Finish
                            </div>
                            :
                            <div
                                onClick={() => {
                                    setTutorialPage(tutorialPage + 1)
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                            >
                                Next
                            </div>
                    }


                </div>

            </Modal.Body>

        </Modal>


        {
            nextSessionTitle &&
            // <Link target="_blank" href={zoomUrl}>
            <div
                onClick={() => {
                    window.open(zoomUrl, "_blank")
                }}
                style={{
                    width: 500,
                    textAlign: "center",
                    background: `url(${buttonBG})`,
                    backgroundSize: "100% 100%",
                    color: "white",
                    fontFamily: "Montserrat",
                    paddingTop: 5,
                    paddingBottom: 5,
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom : 0,
                    margin: "auto",
                    cursor : "pointer"
                }}>
                <div style={{
                    fontSize: "0.8em"
                }}>
                    LIVE NOW
                </div>

                <div onClick={(e) => {
                    e.stopPropagation()
                    setNextSessionTitle(null)
                }} style={{
                    marginTop: "-1.6em",
                    width: "100%",
                    textAlign: "right",
                    marginRight: 100,
                    fontSize: "0.6em",
                    paddingRight: 20
                }}>
                    CLOSE(X)
                </div>

                {nextSessionTitle}

            </div>
            // </Link>

        }


        <Container
            style={{
                background: progression < 1 ? `url(${background})` : "#652aa6",
                backgroundSize: "cover",
                height: "100vh"
            }}
            fluid>

            <Row style={{
                display: "flex",
                justifyContent: "center",
            }}
            >
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 0
                    }}
                    md={12}>
                    {
                        progression < 1 &&
                        <div style={{
                            height: "calc(100vh - 160px)",
                            width: "100%",
                            // background: `url(${banner0})`,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            backgroundSize: "cover"
                        }}>

                            {/*<div style={{*/}
                            {/*    backgroundColor : "white",*/}
                            {/*    paddingLeft : 10,*/}
                            {/*    paddingRight : 10,*/}
                            {/*    borderRadius : 10*/}
                            {/*}}>*/}
                            {/*    <img*/}
                            {/*        style={{*/}
                            {/*            width: 500,*/}
                            {/*            height: 250,*/}
                            {/*            objectFit : "contain"*/}
                            {/*        }}*/}
                            {/*        src={logo}/>*/}
                            {/*</div>*/}


                            <div style={{
                                width: 200,
                                color: Palette.TELIN_PINK,
                                height: 15
                            }}>
                                <div style={{
                                    width: progression * 100 + "%",
                                    backgroundColor: "white",
                                    height: 15
                                }}>

                                </div>
                            </div>

                            <div style={{
                                marginTop: 10,
                                marginBottom: 50,
                                color: "white"
                            }}>
                                Loading the Virtual Exhibition
                            </div>

                        </div>
                    }

                    <Unity
                        style={{
                            height: progression >= 1 ? "calc(100vh - 60px)" : 0,
                            width: "100vw",
                        }}
                        unityContext={unityContext}/>

                </Col>


                <Col md={9}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row-reverse",
                        marginTop: 10
                    }}
                    >
                        <div style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>
                            {
                                progression >= 1 &&
                                <Button
                                    style={{
                                        height: "2em",
                                        width: "2em",
                                        display: "flex",
                                        alignItems: 'center',
                                        justifyContent: "center",
                                        padding: 0
                                    }}
                                    onClick={() => {
                                        unityContext.setFullscreen(true);
                                    }}
                                >
                                    <BiFullscreen color={"white"}/>
                                </Button>
                            }

                        </div>

                        <Button
                            style={{
                                marginRight: 5,
                                height: "2em",
                                width: "2em",
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: "center",
                                padding: 0
                            }}
                            onClick={() => {
                                setTutorialPage(0)
                                setTutorialModal(true)
                            }}
                        >
                            <FaQuestion color={"white"}/>
                        </Button>

                    </div>

                </Col>
            </Row>
        </Container>
        <Container
            style={{padding: 0, marginTop: 50}}
            fluid>
        </Container>
    </>
}
