import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';
import React, {useState} from "react";
import Container from "react-bootstrap/Container";

import 'react-ig-feed/dist/index.css'

import {BrowserRouter, HashRouter, Route, Switch} from "react-router-dom"
import LandingPage from "./components/LandingPage";
import UnityPage from "./components/UnityPage";

import ReactGA from 'react-ga';
import background from "./assets/telin/Background.png";
import ComingSoonPage from "./components/ComingSoonPage";
import HighscorePage from './pages/HighscorePage';

ReactGA.initialize('UA-213169882-1', {
    debug: true,
    titleCase: false,
});
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    const [isLoginShown, setLoginShown] = useState(false)
    const [isRegisterShown, setRegisterShown] = useState(false)

    return (
        <Container fluid style={{
            padding: 0,
            overflowX: "hidden",
        }}>

            <HashRouter>
            {/*<BrowserRouter>*/}
                <Switch>
                    <Route exact path={"/expo"} component={UnityPage}/>
                    <Route exact path={"/demo"} component={LandingPage}/>
                    <Route exact path={"/highscore/:gameId"} component={HighscorePage}/>
                    <Route path={"/"} component={LandingPage}/>

                </Switch>
            {/*</BrowserRouter>*/}
            </HashRouter>


        </Container>
    );

}

export default App;
